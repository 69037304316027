import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

window.Stimulus = Application.start()
const context = require.context(".", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// see https://stimulus.hotwired.dev/handbook/installing#error-handling
Stimulus.handleError = (error, message, detail) => {
  console.warn(message, detail)

  if (typeof Sentry !== "undefined") {
    Sentry.captureException(error)
  }
}
