import { Controller } from "@hotwired/stimulus"
import { useThrottle } from "stimulus-use"
import { upperFirst } from "lodash"

const prefersReducedMotion = () => {
  return window.matchMedia("(prefers-reduced-motion: reduce)").matches
}

export default class MainNavController extends Controller {
  static targets = ["drawer", "dotMenu", "downloadMenu", "dotDownloadMenu", "drawerDownloadMenu"]
  static throttles = MainNavController.targets.map((target) => `toggle${upperFirst(target)}`)

  static NO_SCROLL_ATTRIBUTE = "data-no-scroll"
  static SM_BREAKPOINT = 640

  connect() {
    useThrottle(this)
  }

  toggleDrawer() {
    const target = this.drawerTarget

    window.scrollTo(0, 0)

    if (target.open) {
      this.#closeDrawer()
    } else {
      target.show()
      target.focus()

      document.body.setAttribute(MainNavController.NO_SCROLL_ATTRIBUTE, "")
    }
  }

  toggleDotMenu() {
    if (this.dotMenuTarget.open) {
      this.#closeDialog(this.dotDownloadMenuTarget)
    } else {
      this.#closeDialog(this.downloadMenuTarget)
    }

    this.#toggleMenu(this.dotMenuTarget)
  }

  toggleDownloadMenu() {
    if (!this.downloadMenuTarget.open) {
      this.#closeDialog(this.dotMenuTarget)
    }

    this.#toggleMenu(this.downloadMenuTarget)
  }

  toggleDrawerDownloadMenu() {
    this.#toggleMenu(this.drawerDownloadMenuTarget)
  }

  toggleDotDownloadMenu() {
    this.#toggleMenu(this.dotDownloadMenuTarget)
  }

  handleResizeToNewBreakpoint() {
    if (window.innerWidth >= MainNavController.SM_BREAKPOINT) {
      this.#closeDrawer(true)
    } else {
      this.#allMenuTargets.forEach(this.#closeDialogWithoutAnimation)
    }
  }

  handleEscapeKey(event) {
    if (event.key === "Escape") {
      this.#closeAllMenus()
    }
  }

  handleClickedOutside(event) {
    if (!event.target.closest("[data-main-nav-target][open], [data-action^=click]")) {
      this.#allMenuTargets.forEach(this.#closeDialog)
    }
  }

  handleDrawerClick(event) {
    if (event.target.tagName === "A") {
      this.#closeDialogWithoutAnimation(this.drawerTarget)
    }
  }

  delayedCloseMenu(event) {
    const dialog = event.target.closest("dialog")

    if (dialog) {
      setTimeout(() => this.#closeDialog(dialog), 300)
    }
  }

  #closeDrawer(skipAnimation = false) {
    const targets = [this.drawerDownloadMenuTarget, this.drawerTarget]

    if (skipAnimation) {
      targets.forEach(this.#closeDialogWithoutAnimation)
    } else {
      targets.forEach(this.#closeDialog)
    }

    document.body.removeAttribute(MainNavController.NO_SCROLL_ATTRIBUTE)
  }

  #closeAllMenus() {
    this.#closeDrawer()
    this.#allMenuTargets.forEach(this.#closeDialog)
  }

  get #allMenuTargets() {
    return [
      this.dotMenuTarget,
      this.downloadMenuTarget,
      this.dotDownloadMenuTarget,
      this.drawerDownloadMenuTarget,
    ]
  }

  #toggleMenu(target) {
    if (target.open) {
      this.#closeDialog(target)
    } else {
      target.show()
      target.focus()
    }
  }

  #closeDialog(target) {
    if (!target.open) {
      return
    }

    if (prefersReducedMotion()) {
      this.#closeDialogWithoutAnimation(target)
      return
    }

    const handler = (event) => {
      target.removeEventListener("animationend", handler)

      if (event.animationName === "fadeOut") {
        target.close()
        target.removeAttribute("data-closing")
      }
    }

    target.addEventListener("animationend", handler, { once: true })
    target.setAttribute("data-closing", "")
  }

  #closeDialogWithoutAnimation(target) {
    target.close()
  }
}
