import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { minLength: Number, maxLength: Number }
  static targets = ["field", "length", "format", "lower", "upper", "special", "digit"]

  verify() {
    this.#validateLength()
    this.#validateFormat()

    this.dispatch("validation", { detail: { valid: this.#isValid } })
  }

  #validateLength() {
    this.#updateValidity(this.lengthTarget, this.#isLengthValid)
  }

  get #isLengthValid() {
    const length = this.#password.length
    return length >= this.minLengthValue && length <= this.maxLengthValue
  }

  #validateFormat() {
    this.#updateValidity(this.lowerTarget, this.#hasLower)
    this.#updateValidity(this.upperTarget, this.#hasUpper)
    this.#updateValidity(this.specialTarget, this.#hasSpecial)
    this.#updateValidity(this.digitTarget, this.#hasDigit)
    this.#updateValidity(this.formatTarget, this.#isFormatValid)
  }

  #updateValidity(target, valid) {
    target.setAttribute("data-password-valid", valid.toString())
  }

  get #hasLower() {
    return /[a-z]/.test(this.#password)
  }

  get #hasUpper() {
    return /[A-Z]/.test(this.#password)
  }

  get #hasDigit() {
    return /\d/.test(this.#password)
  }

  get #hasSpecial() {
    return /[^A-Z\d ]/i.test(this.#password)
  }

  get #isFormatValid() {
    return this.#hasLower && this.#hasUpper && this.#hasSpecial && this.#hasDigit
  }

  get #isValid() {
    return this.#isLengthValid && this.#isFormatValid
  }

  get #password() {
    return this.fieldTarget.value
  }
}
