import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add("transition")
  }

  close() {
    this.element.classList.add("opacity-0", "scale-0")

    setTimeout(this.#removeMessage.bind(this), 150)
  }

  #removeMessage() {
    const parent = this.element.parentElement
    this.element.remove()

    if (parent.childElementCount === 0) {
      parent.remove()
    }
  }
}
