import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  toggleVisibility() {
    this.element.toggleAttribute("data-password-visible")

    if (this.fieldTarget.type === "text") {
      this.fieldTarget.type = "password"
    } else {
      this.fieldTarget.type = "text"
    }
  }
}
