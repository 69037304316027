import { Controller } from "@hotwired/stimulus"

export default class LicenseKeyController extends Controller {
  static targets = ["part", "button"]

  static NAVIGATION_KEYS = ["Shift", "Tab", "Enter", "ArrowLeft", "ArrowRight"]
  static EDIT_KEYS = LicenseKeyController.NAVIGATION_KEYS + ["Backspace", "Delete"]

  connect() {
    for (let i = 0; i < this.partTargets.length; i++) {
      this.partTargets[i].partIndex = i
    }
  }

  keydown(event) {
    if (!this.#isEditKey(event.key) && !this.#isValidKey(event.key)) {
      event.preventDefault()
    }
  }

  keyup(event) {
    if (this.#isNavigationKey(event.key)) {
      return
    }

    const length = event.currentTarget.value.length
    const index = parseInt(event.currentTarget.partIndex)

    if (length === 0 && event.key === "Backspace") {
      const prevPart = this.partTargets[index - 1]

      if (prevPart) {
        this.#focusAtEnd(prevPart)
      }
    }

    if (length === 4 && this.#isValidKey(event.key)) {
      const nextPart = this.partTargets[index + 1]

      nextPart ? this.#focusAtStart(nextPart) : this.buttonTarget.focus()
    }
  }

  paste(event) {
    event.preventDefault()

    const paste = (event.clipboardData || window.clipboardData).getData("text")

    const keyParts = paste
      .toUpperCase()
      .replace(/[^A-Z\d]/g, "")
      .match(/(.{1,4})/g)

    if (!keyParts) {
      return
    }

    for (let i = 0; i < keyParts.length; i++) {
      const target = this.partTargets[i]

      if (target) {
        target.value = keyParts[i]
      }
    }

    if (keyParts.length === 4) {
      this.buttonTarget.focus()
    } else {
      this.partTargets[keyParts.length]?.focus()
    }
  }

  #isValidKey(key) {
    return /^[A-Z\d]$/i.test(key)
  }

  #isNavigationKey(key) {
    return LicenseKeyController.NAVIGATION_KEYS.includes(key)
  }

  #isEditKey(key) {
    return LicenseKeyController.EDIT_KEYS.includes(key)
  }

  #focusAtEnd(field) {
    const length = field.value.length

    field.focus()
    field.setSelectionRange(length, length)
  }

  #focusAtStart(field) {
    field.focus()
    field.setSelectionRange(0, 0)
  }
}
