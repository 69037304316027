import { Controller } from "@hotwired/stimulus"

// Used to "prettify" the address-bar display of long URLs that have multiple query parameters
// or even replace entire URLs in browser address bar without actually navigating to a given path
export default class extends Controller {
  static values = {
    path: String,
    retainParams: Array,
  }

  connect() {
    const path = this.pathValue || window.location.pathname

    const url = new URL(path, window.location.origin)
    this.#retainParams(url)

    window.Turbo.navigator.history.replace(url)
  }

  #retainParams(url) {
    const params = new URL(window.location).searchParams

    for (const key of this.retainParamsValue) {
      const value = params.get(key)

      if (value) {
        url.searchParams.append(key, value)
      }
    }
  }
}
