import "@hotwired/turbo-rails"
import { StreamActions, session as turboSession } from "@hotwired/turbo"

// See https://turbo.hotwired.dev/handbook/drive#form-submissions
addEventListener("turbo:submit-start", ({ target }) => {
  const addSubmitterAttributes = () => {
    for (const element of target.elements) {
      element.setAttribute("data-turbo-submitted", true)
    }
  }

  setTimeout(addSubmitterAttributes, turboSession.progressBarDelay)
})

StreamActions.external_redirect = function () {
  window.location.replace(this.target)
}
